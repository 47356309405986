<template>
  <v-container class="mt-4" fluid>
    <v-card class="pa-4">
      <header>
        <h4>{{$_strings.order.STATUS_HISTORY}}</h4>
      </header>
      <v-card class="mt-5 card__rounded">
        <v-row justify="center" class="mb-5">
          <v-col class="d-flex align-center" cols="1">
            <!-- <v-divider class="custom__divider"></v-divider> -->
          </v-col>
          <v-col cols="auto">
            <div class="wrapper__icon">
              <v-img
                src="@/assets/images/pickup-icon.png"
                v-bind:style="isDelivery ? null : grayscale"
              />
              <img
                v-if="isDelivery"
                src="@/assets/images/icon-checkmark-green.png"
                class="checkmarkIcon"
              />
            </div>
          </v-col>
          <v-col class="d-none d-sm-flex align-center">
            <div class="custom_divider">
              <p class="mb-0">{{pickupDuration}}</p>
            </div>
          </v-col>
          <v-col cols="auto">
            <div class="wrapper__icon">
              <img
                src="@/assets/images/truck-icon.png"
                v-bind:style="isUnload ? null : grayscale"
              />
              <img
                v-if="isUnload"
                src="@/assets/images/icon-checkmark-green.png"
                class="checkmarkIcon"
              />
            </div>
          </v-col>
          <v-col class="d-none d-sm-flex align-center">
            <div class="custom_divider">
              <p class="mb-0">{{deliveryDuration}}</p>
            </div>
          </v-col>
          <v-col cols="auto">
            <div class="wrapper__icon">
              <img
                src="@/assets/images/warehouse-icon.png"
                v-bind:style="isCompleted ? null : grayscale"
              />
              <img
                v-if="isCompleted"
                src="@/assets/images/icon-checkmark-green.png"
                class="checkmarkIcon"
              />
            </div>
          </v-col>
          <v-col class="d-flex align-center" cols="1">
            <!-- <v-divider class="custom__divider"></v-divider> -->
          </v-col>
        </v-row>
      </v-card>
      <v-row class="pa-4">
        <v-col class="pb-0" cols="12">
          <span>Status Pesanan</span>
        </v-col>
        <v-col v-for="(status, index) in historyStatus" :key="index"  cols="12">
          <section class="d-flex">
            <div>
              <p class="grey--text pb-0 mb-0">{{dateFormat(status.statusDateTime)}}</p>
              <p class="mt-0 pt-0 mb-0">{{status.status}}</p>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex align-center">
              <p class="pa-0 ma-0">{{timeFormat(status.statusDateTime)}}</p>
            </div>
          </section>
          <v-divider class="mt-5"></v-divider>
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import { dateFormat, timeFormat, dateDiff } from '../../helper/commonHelpers';

export default {
  data() {
    return {
      grayscale: {
        filter: 'grayscale(100%)',
      },
      historyStatus: [],
    };
  },
  mounted() {
    const { params: { historyStatus } } = this.$route;
    if (!historyStatus) this.$router.replace(this.basePath);
    this.historyStatus = historyStatus;
  },
  computed: {
    basePath() {
      const patternGetBasePath = /^(\/\w*){2}/;
      const path = this.$route.path.match(patternGetBasePath)[0];
      return path;
    },
    isDelivery() {
      return this.historyStatus.filter((status) => status.status.toLowerCase() === 'pengiriman').length;
    },
    isUnload() {
      return this.historyStatus.filter((status) => status.status.toLowerCase() === 'di lokasi tujuan').length;
    },
    isCompleted() {
      return this.historyStatus.filter((status) => status.status.toLowerCase().includes('diterima')).length;
    },
    pickupDuration() {
      const pickupStatus = this.historyStatus.find(
        (i) => i.status.toLowerCase() === 'penjemputan',
      );
      const deliverStatus = this.historyStatus.find(
        (i) => i.status.toLowerCase() === 'pengiriman',
      );
      const pickupTime = pickupStatus ? dayjs(pickupStatus.statusDateTime).diff() : null;
      const deliverTime = deliverStatus ? dayjs(deliverStatus.statusDateTime).diff() : null;
      if (!pickupTime || !deliverTime) return '';
      const milliseconds = dayjs(deliverStatus.statusDateTime).diff(pickupStatus.statusDateTime);
      return dateDiff((pickupTime - deliverTime) / 1000) || `${dayjs(milliseconds).format('s')} Detik`;
    },
    deliveryDuration() {
      const deliverStatus = this.historyStatus.find(
        (i) => i.status.toLowerCase() === 'pengiriman',
      );
      const completeStatus = this.historyStatus.find(
        (i) => i.status.toLowerCase().includes('diterima')
          || i.status.toLowerCase().includes('ditolak'),
      );
      const deliverTime = deliverStatus ? dayjs(deliverStatus.statusDateTime).diff() : null;
      const completeTime = completeStatus ? dayjs(completeStatus.statusDateTime).diff() : null;
      if (!deliverTime || !completeTime) return '';
      const milliseconds = dayjs(deliverTime.statusDateTime).diff(completeTime.statusDateTime);
      return dateDiff((deliverTime - completeTime) / 1000) || `${dayjs(milliseconds).format('s')} Detik`;
    },
  },
  methods: {
    dateFormat,
    timeFormat,
  },
};
</script>

<style lang="scss" scoped>
hr::v-deep.custom__divider.v-divider.theme--light {
  border-style: dashed;
  // color: red;
}
.card__rounded {
  border-radius: 50px;
}
.wrapper__icon {
  position: relative;
  .checkmarkIcon {
    position: absolute;
    right: 5px;
    top: 5px;
  }
}
.custom_divider {
  width: 100%;
  height: 25px;
  margin-bottom: 20px;
  border-bottom: 1px dashed rgba(0, 0, 0, 1);
  display: flex;
  justify-content: center;
}
</style>
